import { SearchBar } from "../components/searchBar/SearchBar"
import { SearchBarTest } from "../components/searchBar/SearchBar";
import { IoSearch } from "react-icons/io5";

export const TopNav=({value, setValue})=>{
    return(
        <div className="w-full fixed h-28 top-0 left-0 right-0 flex justify-start p-1 pl-8  items-center border-b-2 border-bggray">
            <SearchBar value={value} setValue={setValue}/>
            <IoSearch className="h-7 w-7 text-lightgray ml-1"/>
        </div>
    )
}


export const TopNavTest=()=>{
    return(
        <div className="w-full h-16 top-0 left-0 right-0 flex justify-start p-2 pl-4  items-center border-b-2 border-bggray">
            <SearchBarTest/>
            <IoSearch className="h-7 w-7 text-lightgray ml-1"/>
        </div>
    )
}