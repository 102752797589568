import { ImageListFrameTest } from "../components/ImageFrame/ImageFrame"

export const StatList=()=>{
    return(
        <div className="h-20 w-full flex justify-start items-center p-3 pl-4 border-b-2 border-bggray">
            <ImageListFrameTest/>
            <div className="w-full flex justify-between items-center p-4" >
                <p className="text-black text-base font-bold">School Fee</p>
                <p className="text-black text-sm font-semibold">200,000 FCFA</p>
            </div>
        </div>
    )
}