import { NavIcons1, NavIcons2, NavIcons3, NavIcons4 } from "../components/Icons/NavIcons"

export const BottomNav=()=>{
    return(
        <div className="w-full h-16 absolute bottom-0 rounded-t-2xl flex justify-center items-center border-t-2 border-bggray bg-white">
            <NavIcons1 selected={false}/>
            <NavIcons2 selected={false}/>
            <NavIcons3 selected={false}/>
            <NavIcons4 selected={false}/>
        </div>
    )
}