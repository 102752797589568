import { Camera } from "../../layout/Camera"
import { TopOtions } from "../../layout/TopOptions"
import { TopNav, TopNavTest } from "../../layout/TopNav"
import { ReceiptItemTest2 } from "../../layout/ReceiptList"
import { Datepicker } from "../../components/DatePicker/DatePicker"

export const Receipt=()=>{
    return(
        <div className="h-screen w-full bg-white max-[600px]:h-screen overflow-hidden">
            <TopNavTest/>
            <TopOtions/>
            <div className="h-full w-full overflow-hidden">
                <div className="w-full h-10 pr-4 pl-4 mt-2 border-b-2 border-bggray">
                    <p className="text-sm text-black float-right font-semibold text-center">Income</p>
                    <Datepicker/>
                </div>
                <div className="h-full w-full overflow-y-scroll pb-96">
                    <ReceiptItemTest2 type={'expense'}/>
                    <ReceiptItemTest2 type={'income'}/>
                    <ReceiptItemTest2 type={'income'}/>
                    <ReceiptItemTest2 type={'income'}/>
                    <ReceiptItemTest2 type={'expense'}/>
                    <ReceiptItemTest2 type={'income'}/>
                    <ReceiptItemTest2 type={'expense'}/>
                    <ReceiptItemTest2 type={'income'}/>
                </div>
            </div>
            <Camera/>
        </div>
    )
}