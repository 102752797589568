import { GetStartedBtn, NavFAQBtn } from "../components/buttons/Buttons" 
import logo from '../assets/images/logo.png'

export const NavBar=()=>{
    return(
        <div className="h-10 w-4/5 m-l-10 m-r-10 flex justify-between items-center m-auto absolute top-5 left-0 right-0 z-10 max-[600px]:w-full max-[600px]:pl-4 max-[600px]:pr-4">
            <img src={logo} alt="" className="w-24 h-24 max-[600px]:w-16 max-[600px]:h-16"/>
            <div className=" table-fixed h-10 w-56 flex justify-between items-center max-[600px]:justify-end">
                <NavFAQBtn/>
                <GetStartedBtn/>
            </div>
        </div>
    )
}