import { ImCross } from "react-icons/im";
import { FaCheck } from "react-icons/fa";

export const Cards=()=>{
    return(
        <div className=" w-screen min-h-full bg-thickBlue pb-20  max-[600px]:flex max-[600px]:flex-col ">
            <h3 className="
                text-4xl text-white 
                font-bold pt-32 mb-0 
                text-center m-auto 
                max-[600px]:text-2xl
                max-[600px]:w-5/6
                "
            >Tired of Losing Important Receipts</h3>
            
            <div className="w-4/5 h-auto m-auto flex justify-between p-20 max-[600px]:flex-col  max-[600px]:items-center">
                <div className="min-h-96 w-96 bg-white flex flex-col justify-center items-center rounded-3xl p-12 max-[600px]:w-80 max-[600px]:mb-20">
                    <h3 className="text-center text-black font-bold text-xl">Finances without ReceiptWallet</h3>
                    <ul className="list-none mt-10 w-5/6">
                        <li className="flex justify-start items-center mt-4"><ImCross className="h-10 w-10 pr-4 text-red"/><p className="text-sm text-red w-3/4">Misplaced or lost receipts</p></li>
                        <li className="flex justify-start items-center mt-4"><ImCross className="h-10 w-10 pr-4 text-red"/><p className="text-sm text-red w-3/4">Huge piles makes it difficult to track and organize</p></li>
                        <li className="flex justify-start items-center mt-4"><ImCross className="h-10 w-10 pr-4 text-red"/><p className="text-sm text-red w-3/4">Illegibility of old receipts</p></li>
                        <li className="flex justify-start items-center mt-4"><ImCross className="h-10 w-10 pr-4 text-red"/><p className="text-sm text-red w-3/4">Inefficient Record-keeping for tax pupose</p></li>
                        <li className="flex justify-start items-center mt-4"><ImCross className="h-10 w-10 pr-4 text-red"/><p className="text-sm text-red w-3/4">Waste of time to find receipts</p></li>
                    </ul>
                </div>

                <div className="min-h-96 w-96 bg-white flex flex-col justify-center items-center rounded-3xl p-12 max-[600px]:w-80">
                    <h3 className="text-center text-black font-bold text-xl">Finances with ReceiptWallet</h3>
                    <ul className="list-none mt-10 w-5/6">
                        <li className="flex justify-start items-center mt-4"><FaCheck className="h-10 w-10 pr-4 text-green"/><p className="text-sm text-green w-3/4">No more scrambling to find receipts</p></li>
                        <li className="flex justify-start items-center mt-4"><FaCheck className="h-10 w-10 pr-4 text-green"/><p className="text-sm text-green w-3/4">Accurate record-keeping for taxes</p></li>
                        <li className="flex justify-start items-center mt-4"><FaCheck className="h-10 w-10 pr-4 text-green"/><p className="text-sm text-green w-3/4">Eliminates tedious manual entry of expenses, saving time and effort</p></li>
                        <li className="flex justify-start items-center mt-4"><FaCheck className="h-10 w-10 pr-4 text-green"/><p className="text-sm text-green w-3/4">Easily categorize expenses and track spending</p></li>
                        <li className="flex justify-start items-center mt-4"><FaCheck className="h-10 w-10 pr-4 text-green"/><p className="text-sm text-green w-3/4">Securely stores receipts in your Wallet</p></li>
                    </ul>
                </div>
            </div>
        </div>
    )
}