
export const SearchBar=({value, setValue})=>{

    const handleChange=(e)=>{
        setValue(e.target.value)
    }

    return(
        <div className="h-10 w-3/4">
            <input type="text" value={value} onChange={handleChange} placeholder="Search" className="h-10 w-full focus:outline-none bg-bggray rounded-lg  text-lightgray p-2 pl-4 text-base" />
        </div>
    )
}


export const SearchBarTest=()=>{

    return(
        <div className="h-10 w-3/4">
            <input type="text" placeholder="Search" className="h-10 w-full focus:outline-none  bg-bggray rounded-lg text-lightgray p-2 pl-4 text-base" />
        </div>
    )
}