import imageurl from '../../assets/images/logo.png'

export const ImageListFrame=({imageurl})=>{
    return(
        <img src={imageurl} alt="receipt" className="h-20 w-20 rounded-sm bg-contain"/>
    )
}

export const ImageListFrameTest=()=>{
    return(
        <img src={imageurl} alt="receipt" className="h-20 w-20 rounded-sm bg-contain"/>
    )
}

export const ProfilePic=()=>{
    return(
        <img src={imageurl} alt="receipt" className="h-24 w-24 rounded-full bg-contain border-2 border-lightestgray"/>
    )
}