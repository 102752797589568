import { NavBar } from "./navbar";
import { MainActionBtn } from "../components/buttons/Buttons";

export const Hero =()=>{
    return(
        <div className="h-full w-screen bg-hero-pattern bg-cover flex flex-col max-[600px]:h-full max-[600px]:bg-right">
            <NavBar/>
            <div className="h-fit w-4/5 mt-52 flex flex-col m-auto max-[600px]:mt-60 max-[600px]:w-screen max-[600px]:pl-1 max-[600px]:pr-2">
                <h3 id="maintext" 
                    className="bg-white p-2 text-left
                    text-black w-1/2 text-4xl font-bold 
                    animate-typing whitespace-nowrap truncate 
                    cursor-pointer hover:animate-typing
                    max-[600px]:text-center  max-[600px]:text-2xl
                    max-[600px]:w-full max-[600px]:m-1
                    ">Paper Receipt Got You Down?</h3>
                <h3 className="p-2 text-left 
                    text-white text-4xl font-semibold 
                    max-[600px]:text-center max-[600px]:p-1  
                    max-[600px]:text-2xl">Say Hi to Free Receipt </h3>
                <h3 className="p-2 text-left 
                    text-white text-4xl font-semibold 
                    max-[600px]:text-center max-[600px]:p-1  
                    max-[600px]:text-2xl">Saving & Expense Tracking!</h3>

                <div className="mt-20 flex justify-start items-center">
                    <MainActionBtn/>
                </div>
            </div>
        </div>
    )
}