import { SignOutButton } from "@clerk/clerk-react";
import { FcGoogle, FcPhone } from "react-icons/fc";
import { MdOutlineMail } from "react-icons/md";
import { MdOutlinePhone } from "react-icons/md";
import { useNavigate } from "react-router-dom";

export const MainActionBtn=()=>{
    return(
        <button 
            className='
            w-50 
            h-10 flex 
            font-bold 
            rounded-full 
            items-center 
            text-gray-800 
            p-8
            text-xl
            rounded-2 
            bg-golden
            duration-500
            hover:duration-500
            hover:text-white
            hover:border-white
            hover:border-2
            hover:bg-none max-[600px]:m-auto max-[600px]:text-2xl'
        >Upload Now!</button>
    )
}


export const GetStartedBtn=()=>{
    return(
        <button 
            className='w-25
            h-8 flex 
            font-bold 
            text-sm
            rounded-full 
            items-center 
            text-gray-800 
            p-4 
            rounded-2 
            bg-golden
            '
        >Get Started</button>
    )
}

export const NavFAQBtn=()=>{
    return(
        <button 
            className='w-50
            h-8 flex 
            font-bold 
            text-sm
            rounded-full 
            items-center 
            text-white
            p-4 
            rounded-2 
            border-2
            border-white
            hover:bg-golden
            hover:text-thickBlue 
            hover:border-none
            max-[600px]:mr-2'
        >FAQ</button>
    )
}

export const PricingBtn=({text})=>{

    const nav = useNavigate()
    return(
        <button 
            className='
            w-full
            h-9 flex 
            font-bold 
            text-base 
            items-center
            text-center
            justify-center 
            text-gray-800 
            p-4 
            rounded-xl
            bg-golden
            '
            onClick={()=>nav('/Home')}
        >{text}</button>
    )
}



export const GoogleLoginBtn=({clicked})=>{
    
    return(
        <div className=" group w-3/4 h-11 p-2 rounded-3xl flex items-center bg-lightblue  text-base text-center mb-2 hover:border-lightgray hover:border-2  hover:bg-white duration-300" onClick={()=>clicked}>
            <FcGoogle className="h-7 w-7 rounded-2xl bg-white"/>
            <p className=" w-3/4 text-center text-white font-semibold group-hover:text-lightblue">Join with Google</p>
        </div>
    )
}


export const MailBtn=()=>{
    
    return(
        <div className="w-3/4 h-11 p-2 rounded-3xl flex  items-center bg-white border-lightgray border-solid border-2 text-base text-center mb-6">
            <MdOutlineMail className="h-7 w-7 rounded-2xl bg-white  text-lightgray"/>
            <p className=" w-3/4 text-center  text-lightgray font-semibold">Use Mail</p>
            
        </div>
    )
}


export const Phonebtn=()=>{
    
    return(
        <div className="w-3/4 h-11 p-2 rounded-3xl flex  items-center bg-white border-lightgray border-solid border-2 text-base text-center mb-6">
            <MdOutlinePhone className="h-7 w-7 rounded-2xl bg-white text-lightgray"/>
            <p className=" w-3/4 text-center text-lightgray font-semibold">Join with Phone</p>
        </div>
    )
}


export const ProfileButtons=({onClickHandler, text})=>{
    return(
        <button 
            onClick={()=>onClickHandler}
            className="w-3/4 h-12 p-2 pl-6 mb-4 rounded-3xl text-lightgray bg-bggray border-none text-base text-left"
        >
            
            {text === 'Logout'? <SignOutButton/>: text}
        </button>
    )
}