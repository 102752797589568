import { StatList } from "../../layout/StatsList"
import { Datepicker } from "../../components/DatePicker/DatePicker"
import { IcomeToggle } from "../../components/Toggle/IncomeExpense"

export const Extra=()=>{
    return(
        <div className="h-screen w-full bg-thickBlue max-[600px]:h-screen overflow-hidden">
            <div className="h-1/4 w-full bg-thickBlue flex flex-col items-center justify-center pl-7 overflow-hidden">
                <p className="w-full text-white font-semibold text-lg text-left pb-3">Expense</p>
                <p className="w-full text-white font-bold text-3xl text-left">1,450,000 <span className=" text-white font-bold text-base">FCFA</span></p>
            </div>
            <div className=" bg-white rounded-t-3xl pt-8  h-3/4 w-full  overflow-hidden">
                <div className="w-full h-14 flex justify-center items-center pb-5 ">
                    <IcomeToggle/>
                </div>
                
                <div className="w-full h-10 pr-4 pl-4 mt-2 border-b-2 border-bggray overflow-hidden">
                    <Datepicker/>
                </div>

                <div className="h-full w-full overflow-y-scroll pb-60">
                    <StatList/>
                    <StatList/>
                    <StatList/>
                </div>
                
            </div>
            
        </div>
    )
}