import { MdHome } from "react-icons/md";
import { IoReceiptSharp } from "react-icons/io5";
import { FaChartLine } from "react-icons/fa";
import { CgProfile } from "react-icons/cg";
import { FaShareAlt } from "react-icons/fa";
import { MdOutlineDriveFileMove } from "react-icons/md";
import { CiEdit } from "react-icons/ci";
import { MdOutlineDelete } from "react-icons/md";

import { FaFileImport } from "react-icons/fa6";
import { BiSolidCategory } from "react-icons/bi";
import { useNavigate } from "react-router-dom";


export const NavIcons1=({selected})=>{
    const nav = useNavigate()
    return(
        <button onClick={()=>nav('/Home')} className=" group w-28 border-none bg-none flex flex-col justify-center items-center">
            <MdHome className={selected? "h-7 w-7 text-lightblue" :"h-7 w-7 text-lightgray group-hover:text-lightblue"}/>
            <p className={ selected? "text-xs text-lightblue" : "text-xs text-lightgray group-hover:text-lightblue"}>Home</p>
        </button>
    )
}

export const NavIcons2=({selected})=>{
    const nav = useNavigate()
    return(
        <button onClick={()=>nav('/Receipt')} className=" group w-28 border-none bg-none flex flex-col justify-center items-center">
            <IoReceiptSharp className={selected? "h-7 w-7 text-lightblue" :"h-7 w-7 text-lightgray group-hover:text-lightblue"}/>
            <p className={ selected? "text-xs text-lightblue" : "text-xs text-lightgray group-hover:text-lightblue"}>Receipt</p>
        </button>
    )
}

export const NavIcons3=({selected})=>{
    const nav = useNavigate()
    return(
        <button onClick={()=>nav('/Stats')} className=" group w-28 border-none bg-none flex flex-col justify-center items-center">
            <FaChartLine className={selected? "h-7 w-7 text-lightblue" :"h-7 w-7 text-lightgray group-hover:text-lightblue"}/>
            <p className={ selected? "text-xs text-lightblue" : "text-xs text-lightgray group-hover:text-lightblue"}>Extra</p>
        </button>
    )
}

export const NavIcons4=({selected})=>{
    const nav = useNavigate()
    return(
        <button onClick={()=>nav('/Profile')} className=" group w-28 border-none bg-none flex flex-col justify-center items-center">
            <CgProfile className={selected? "h-7 w-7 text-lightblue" :"h-7 w-7 text-lightgray group-hover:text-lightblue"}/>
            <p className={ selected? "text-xs text-lightblue" : "text-xs text-lightgray group-hover:text-lightblue"}>Profile</p>
        </button>
    )
}



// options buttons

export const ShareBtn=()=>{
    return(
        <button className=" group w-28 border-none bg-none flex flex-col justify-center items-center">
            <FaShareAlt className="h-7 w-7 text-lightgray"/>
            <p className="text-xs text-lightgray">Share</p>
        </button>
    )
}

export const DeleteBtn=()=>{
    return(
        <button className=" group w-28 border-none bg-none flex flex-col justify-center items-center">
            <MdOutlineDelete className="h-7 w-7 text-lightgray"/>
            <p className="text-xs text-lightgray">Delete</p>
        </button>
    )
}

export const MoveBtn=()=>{
    return(
        <button className=" group w-28 border-none bg-none flex flex-col justify-center items-center">
            <MdOutlineDriveFileMove className="h-7 w-7 text-lightgray"/>
            <p className="text-xs text-lightgray">Move</p>
        </button>
    )
}


export const RenameBtn=()=>{
    return(
        <button className=" group w-28 border-none bg-none flex flex-col justify-center items-center">
            <CiEdit className="h-7 w-7 text-lightgray"/>
            <p className="text-xs text-lightgray">Rename</p>
        </button>
    )
}

export const ImportReceipt=()=>{
    return(
        <button className=" group w-28 border-none bg-none flex flex-col justify-center items-center">
            <FaFileImport className="h-10 w-10 text-blueColombia"/>
            <p className="text-xs text-black font-semibold">Rename</p>
        </button>
    )
}


export const Category=()=>{
    return(
        <button className=" group w-28 border-none bg-none flex flex-col justify-center items-center">
            <BiSolidCategory className="h-10 w-10 text-brightgreen"/>
            <p className="text-xs text-black font-semibold">Rename</p>
        </button>
    )
}



