import { PricingBtn } from "../buttons/Buttons"
import { FaCheck } from "react-icons/fa";
import { ImCross } from "react-icons/im";

export const PricingCard =()=>{
    return(
        <div className="min-h-96 w-96 bg-thickBlue  border-4 relative flex flex-col justify-start  rounded-3xl p-12 pt-6 max-[600px]:mb-20 max-[600px]:w-80">
            
            <h3 className="text-golden text-xl font-semibold">Starter</h3>
            <p className=" text-white font-bold text-2xl pt-3">
                <span className="text-sm text-lightgray line-through mr-2">
                    2000 FCFA
                </span>
                Free
                <span className="text-sm text-white">
                    
                </span>
            </p>

            {/* list of available tools */}
            <ul className="list-none mt-10 mb-20">
                <li className="text-base text-white flex justify-start items-center mb-2 text-left"><FaCheck className="h-4 w-4 text-white mr-2"/>10 Uploads per month</li>
                <li className="text-base text-white flex justify-start items-center mb-2 text-left"><FaCheck className="h-4 w-4 text-white mr-2"/>Unlimited access to receipts</li>
                <li className="text-base text-white flex justify-start items-center mb-2 text-left"><FaCheck className="h-4 w-4 text-white mr-2"/>Duration 4 year</li>
                <li className="text-base text-lightgray flex justify-start items-center mb-2 text-left"><ImCross className="h-4 w-4 text-lightgray mr-2"/>Manage csv and excel data</li>
                <li className="text-base text-lightgray flex justify-start items-center mb-2 text-left"><ImCross className="h-4 w-4 text-lightgray mr-2"/>Financial tracking</li>
                <li className="text-base text-lightgray flex justify-start items-center mb-2 text-left"><ImCross className="h-4 w-4 text-lightgray mr-2"/>Backup</li>

            </ul>

            {/* get started button */}
            <div className="w-4/5  bottom-8 m-auto left-0 right-0 flex justify-center items-center absolute">
                <PricingBtn text={"Get Started"}/>
            </div>
        </div>
    )
}

export const PricingCardPro =()=>{
    return(
        <div className="min-h-96 w-96 bg-thickBlue border-golden border-4 relative flex flex-col justify-start  rounded-3xl p-12 pt-6 max-[600px]:mb-20 max-[600px]:w-80">
            <div className="w-full top-0 text-center flex justify-center">
                <h4 className="w-16 h-4 pb-6 rounded-full font-bold text-black bg-golden text-center items-center -top-4 m-auto absolute ">PRO</h4>
            </div>
            <h3 className="text-golden text-xl font-semibold">Business</h3>
            <p className=" text-white font-bold text-2xl pt-3">
                <span className="text-sm text-lightgray line-through mr-2">
                    4000FCFA
                </span>
                2,000
                <span className="text-sm text-white">
                    FCFA/month
                </span>
            </p>

            {/* list of available tools */}
            <ul className="list-none mt-10 mb-20">
                <li className="text-base text-white flex justify-start items-center mb-2 text-left"><FaCheck className="h-4 w-4 text-white mr-2"/>1000+ Uploads per month</li>
                <li className="text-base text-white flex justify-start items-center mb-2 text-left"><FaCheck className="h-4 w-4 text-white mr-2"/>Unlimited access to receipts</li>
                <li className="text-base text-white flex justify-start items-center mb-2 text-left"><FaCheck className="h-4 w-4 text-white mr-2"/>Duration 10 years</li>
                <li className="text-base text-white flex justify-start items-center mb-2 text-left"><FaCheck className="h-4 w-4 text-white mr-2"/>Manage csv and excel data</li>
                <li className="text-base text-white flex justify-start items-center mb-2 text-left"><FaCheck className="h-4 w-4 text-white mr-2"/>Financial tracking</li>
                <li className="text-base text-white flex justify-start items-center mb-2 text-left"><FaCheck className="h-4 w-4 text-white mr-2"/>Backup</li>
            </ul>

            {/* go pro button */}
            <div className="w-4/5 bottom-8 m-auto left-0 right-0 flex justify-center items-center absolute">
                <PricingBtn text={"Go Pro"}/>
            </div>
        </div>
    )
}