import { Hero } from "../../layout/Hero"
import { Banner } from "../../layout/Banner"
import { YTvideoBox } from "../../layout/YTvideo"
import { Cards } from "../../layout/Cards"
import { Pricing } from "../../layout/pricing"
import { Footer } from "../../layout/footer"
import { FAQ } from "../../layout/FAQ"

export const LandingPage = ()=>{
    return(
        <div className="h-screen w-full bg-thickBlue max-[600px]:h-screen">
            <Hero/>
            <Banner/>
            <YTvideoBox/>
            <Cards/>
            <Pricing/>
            <FAQ/>
            <Footer/>
        </div>
    )
}