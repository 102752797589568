import { ImageListFrame, ImageListFrameTest } from "../components/ImageFrame/ImageFrame"
import { FaRegSquare } from "react-icons/fa";
import { FaSquare } from "react-icons/fa";

export const ReceiptItem =({key, imageurl, Name, date})=>{
    return(
        <div key={key} className="h-64 w-full flex justify-start items-center p-3 pl-5 border-b-2 border-lightestgray">
            <ImageListFrame imageurl={imageurl}/>
            <div className="w-1/3 flex flex-col justify-start items-start pt-3">
                <p className=" text-lg text-black font-semibold">{Name}</p>
                <p className=" text-sm text-lightestgray">{date}</p>
            </div>
            <div className="w-2/3 h-60 flex justify-end items-center pr-1">
                <FaRegSquare className="h-6 w-6 text-lightestgray"/>
            </div>
        </div>
    )
}


export const ReceiptItemTest =()=>{
    return(
        <div  className="h-24 w-full flex justify-start items-center p-3 pl-4 border-b-2 border-bggray">
            <ImageListFrameTest/>
            <div className="w-1/3 flex flex-col justify-start items-start pt-0">
                <p className=" text-base text-black font-semibold">School Fee</p>
                <p className=" text-sm text-lightestgray">09-07-2024</p>
            </div>
            <div className="w-2/3 h-60 flex justify-end items-center pr-1">
                <FaRegSquare className="h-6 w-6 text-lightestgray"/>
            </div>
        </div>
    )
}

export const ReceiptItemTest2 =({type})=>{
    return(
        <div  className={(type==='income')?"h-24 w-full flex justify-start items-center p-3 pl-4 border-b-2 border-l-4 border-bggray border-l-brightgreen" :"h-24 w-full flex justify-start items-center p-3 pl-4 border-b-2 border-l-4 border-bggray border-l-red"}>
            <ImageListFrameTest/>
            <div className="w-1/3 flex flex-col justify-start items-start pt-0">
                <p className=" text-base text-black font-semibold">School Fee</p>
                <p className=" text-sm text-lightestgray">09-07-2024</p>
            </div>
            <div className="w-2/3 h-60 flex justify-end items-center pr-1">
                <FaRegSquare className="h-6 w-6 text-lightestgray"/>
            </div>
        </div>
    )
}