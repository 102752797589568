import { useEffect, useState } from 'react';
import {Routes, Route} from 'react-router-dom'
import './App.css';
import { LandingPage } from './pages/Landing';
import {Home} from './pages/Home'
import { Login } from './pages/Login';
import { BottomNav } from './layout/BottomNav';
import { Receipt } from './pages/Receipt';
import { Extra } from './pages/Extra';
import { Profile } from './pages/Profile';
import axios from 'axios'
import {
  ClerkProvider,
  SignedIn,
  SignedOut,
  UserButton,
  useUser,
  SignIn,
  SignUp,
  RedirectToSignIn,
  SignOutButton,
} from "@clerk/clerk-react";
import { useAuth } from "@clerk/clerk-react";


const clerkPubKey = 'pk_test_Y2VudHJhbC1lZ3JldC02Ny5jbGVyay5hY2NvdW50cy5kZXYk';



function App() {

  
  if (!clerkPubKey) {
    throw new Error("Missing Publishable Key")
  }

  return (
    <div className="h-screen">
        <ClerkProvider publishableKey={clerkPubKey}>
          <Routes>
            <Route path='/' element={<LandingPage/>}/>
            <Route
              path="/sign-in/*"
              element={<SignIn routing="path" path="/sign-in" />}
            />
            <Route
              path="/sign-up/*"
              element={<SignUp routing="path" path="/sign-up" />}
            />
            <Route path='/Home' element={<SignedIn><Home/></SignedIn>}/>
            <Route path='/Receipt' element={<SignedIn><Receipt/></SignedIn>}/>
            <Route path='/Stats' element={<SignedIn><Extra/></SignedIn>}/>
            <Route path='/Profile' element={<SignedIn><Profile/></SignedIn>}/>
            <Route path='/logout' element={<SignOutButton/>}/>
          </Routes>
          <SignedOut><RedirectToSignIn/></SignedOut>
          <BottomNav/>
      </ClerkProvider>
      
    </div>
  );

}


export default App;
