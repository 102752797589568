import { TopNav, TopNavTest } from "../../layout/TopNav"
import { ListOption } from "../../layout/ListOptions"
import { AdBanner } from "../../layout/AdBanner"
import { BottomNav } from "../../layout/BottomNav"
import { ReceiptItem, ReceiptItemTest } from "../../layout/ReceiptList"
import { Camera } from "../../layout/Camera"
import { useUser } from "@clerk/clerk-react";


export const Home = ()=>{

    const { isLoaded, isSignedIn, user } = useUser();
 
    if (!isLoaded || !isSignedIn) {
      return null;
    }else{
        console.log(user.imageUrl)
    }

    return(
        <div className="h-screen w-full bg-white max-[600px]:h-screen">
            <TopNavTest/>
            <AdBanner/>
            <div className="w-full">
                <ListOption/>
                <ReceiptItemTest/>
                <ReceiptItemTest/>
            </div>
            <Camera/>
        </div>
    )
}