import { ProfileButtons } from "../../components/buttons/Buttons"
import { ProfilePic } from "../../components/ImageFrame/ImageFrame"
import { AdBanner } from "../../layout/AdBanner"


export const Profile =()=>{

    const onClickHandler=()=>{
        console.log('clicked')
    }

    return(
        <div className="h-screen w-full bg-white max-[600px]:h-screen">
            <div className="w-full flex flex-col justify-center items-center pt-16">
                <ProfilePic/>
                <p className="text-lightgray font-medium text-sm">nyaah73@gmail.com</p>
            </div>
            <div className="w-3/4 m-auto flex justify-center items-center pt-4">
                <AdBanner/>
            </div>
            <div className="w-full flex flex-col justify-center items-center">
                <ProfileButtons text={'Feedback'} onClickHandler={onClickHandler}/>
                <ProfileButtons text={'Invite a Friend'} onClickHandler={onClickHandler}/>
                <ProfileButtons text={'Help and Support'} onClickHandler={onClickHandler}/>
                <ProfileButtons text={'Logout'} onClickHandler={onClickHandler}/>
            </div>
        </div>
    )
}