import { PricingCard, PricingCardPro } from "../components/pricing/pricingcard"
import { IoIosGift } from "react-icons/io";

export const Pricing=()=>{
    return(
        <div className="w-screen min-h-full pb-20 bg-fadethickblue max-[600px]:flex max-[600px]:flex-col ">
            <h3 className="text-base pt-24 text-golden text-center font-semibold">Pricing</h3>
            <h3 className="w-1/2 text-4xl pt-8 text-white text-center font-bold m-auto max-[600px]:w-4/5 max-[600px]:text-2xl">Secure and Easily Access Your Receipts From Anywhere</h3>
            <h4 className="w-80 m-auto text-center mt-10 text-base text-white font-semibold flex justify-center mb-1">
                <span className="text-base text-golden text-center font-semibold pr-2 flex justify-center">
                <IoIosGift className="h-6 w-6 text-golden block mr-2 "/>
                    100% OFF
                </span>
                For first 100 users
            </h4>
            <div className="w-4/5 h-auto m-auto flex justify-between p-20 max-[600px]:flex-col max-[600px]:items-center ">
                <PricingCard/>
                <PricingCardPro/>
            </div>
        </div>
    )
}