import { FaXTwitter } from "react-icons/fa6";
import { FaReddit } from "react-icons/fa";
import { FaProductHunt } from "react-icons/fa6";
import { FaGithub } from "react-icons/fa";

export const Banner=()=>{
    return(
        <div className="h-36 w-screen bg-black flex justify-center items-center max-[600px]:h-28">
            <div className="h-full w-1/2 m-auto flex justify-evenly items-center max-[600px]:w-2/3">
                <p className="text-base font-semibold text-lightgray text-center max-[600px]:text-xs">Featured on</p>
                <FaXTwitter className="h-9 w-16  text-lightgray hover:text-white duration-150 max-[600px]:h-7 max-[600px]:w-10"/>
                <FaReddit className="h-9 w-16 text-lightgray hover:text-orange duration-150 max-[600px]:h-7 max-[600px]:w-10"/>
                <FaProductHunt className="h-9 w-16  text-lightgray hover:text-orange duration-150 max-[600px]:h-7 max-[600px]:w-10"/>
                <FaGithub className="h-9 w-16 text-lightgray hover:text-white duration-150 max-[600px]:h-7 max-[600px]:w-10"/>
            </div>
        </div>
    )
}