import { useState } from "react";
import DatePicker from "react-datepicker";
import CalendarContainer from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


export const Datepicker=()=>{
    const [startDate, setStartDate] = useState(new Date());
    const renderMonthContent = (month, shortMonth, longMonth, day) => {
        const fullYear = new Date(day).getFullYear();
        const tooltipText = `Tooltip for month: ${longMonth} ${fullYear}`;
    
        return <span title={tooltipText}>{shortMonth}</span>;
      };

    const MyContainer = ({ className, children }) => {
    return (
        <div style={{ marginLeft: '200px', width: '240px', color: "#fff" }}>
            <div className={className}>{children}</div>
        </div>
    );
    };

    return(
        <div className="h-8 w-24 flex justify-center items-center bg-bggray rounded-2xl float-left ">
            <DatePicker className="bg-none text-center text-sm focus:outline-none" closeOnScroll={true} selected={new Date()}
                renderMonthContent={renderMonthContent}
                showMonthYearPicker
                calendarContainer={MyContainer}
                dateFormat="MM/yyyy" 
            />
        </div>
    )
}

