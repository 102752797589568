import { ImportReceipt, Category } from "../components/Icons/NavIcons"

export const TopOtions=()=>{
    return(
        <div className="w-full h-20 mt-3 flex justify-center items-center border-b-2 border-bggray">
            <ImportReceipt/>
            <Category/>
            <Category/>
        </div>
    )
}