import logo from '../assets/images/logo.png'
import { FaXTwitter } from "react-icons/fa6";

export const Footer=()=>{
    return(
        <div className="min-h-full w-screen border-t-2 relative border-lightestgray bg-fadethickblue pb-32 max-[600px]:pr-0 max-[600px]:pb-0 max-[600px]:flex max-[600px]:flex-col max-[600px]:justify-center max-[600px]:items-center">
            <div className="pt-40 pl-20 flex justify-between items-center max-[600px]:flex-col max-[600px]:justify-center">
                {/* about app */}
                <div className="w-64 h-96 text-left max-[600px]:h-60">
                    <div className="w-52 h-10 pl-0 flex justify-start items-center">
                        <img src={logo} alt="logo" className="w-12 h-12 mr-1 max-[600px]:w-16 max-[600px]:h-16"/>
                        <h3 className='text-center text-white font-bold'>ReceiptWallet</h3>
                    </div>
                    <p className=" w-full mt-4 text-xs text-lightestgray">Free Yourself from Paper Receipts! Upload, Track, and Be Financially Organized.</p>
                    <p className="mt-4 text-xs text-lightestgray">Copyright © 2024 - All rights reserved</p>
                </div>

                {/* links */}
                <div className="w-64 h-96 text-left ml-80 max-[600px]:ml-0 max-[600px]:h-60">
                    <h3 className='text-xl text-left mb-2 text-white font-bold'>LINKS</h3>
                    <p className='text-lightestgray'>Support</p>
                    <p className='text-lightestgray'>FAQ</p>
                    <p className='text-lightestgray'>Affiliates</p>
                </div>

                {/* legals */}
                <div className="w-64 h-96 text-left max-[600px]:h-60">
                    <h3 className='text-xl text-left mb-2 text-white font-bold'>LEGALS</h3>
                    <p className='text-lightestgray'>Terms of services</p>
                    <p className='text-lightestgray'>Privacy policy</p>
                </div>
            </div>


            {/* my identity */}
            <div className='absolute bottom-40 pl-20 pr-4 max-[600px]:bottom-10 max-[600px]:pl-4' >
                <h3 className='w-full text-left mt-4 text-sm text-lightestgray max-[600px]:text-xs max-[600px]:leading-5 max-[600px]:justify-start '>Hey y’ll, I’m Larrien the creator of ReceiptWallet. To see more of my work, follow me on <a className='underline font-bold ml-1' href='https://x.com/Larrien_Saam?t=qKXKy2WiOUZxqowCDFkp9w&s=09'>Twitter</a> </h3>
            </div>
        </div>
    )
}