import { FaPlus } from "react-icons/fa";

export const FAQ=()=>{
    return(
        <div className="w-screen min-h-full pb-40 bg-thickBlue max-[600px]:flex max-[600px]:flex-col ">
            <div className="w-4/5 h-auto flex justify-between items-center pt-8 m-auto max-[600px]:flex-col">
                <div className="h-auto w-1/2 text-left flex flex-col -mt-32  justify-center items-start max-[600px]:w-full max-[600px]:text-center max-[600px]:mt-20">
                    <h3 className="text-base text-golden  font-semibold max-[600px]:w-full">FAQ</h3>
                    <h3 className="w-full text-3xl pt-2 text-white  font-bold m-auto max-[600px]:w-4/5 max-[600px]:text-2xl">Frequently Asked Questions</h3>
                    <p className="text-lightestgray text-sm max-[600px]:w-full">Have more questions, ask me on <a href='https://x.com/Larrien_Saam?t=qKXKy2WiOUZxqowCDFkp9w&s=09' className='underline font-bold ml-1'> Twitter </a>.</p>
                </div>

                {/* questions */}
                <div className=" w-2/5 h-auto text-left mt-40 max-[600px]:w-full max-[600px]:mt-12">
                    <p className="w-full text-lg flex justify-between items-center text-white font-semibold p-5">Why do i need ReceiptWallet?<FaPlus className="h-5 w-5 text-white cursor-pointer "/></p>
                    <div className="h-1 w-full bg-white">

                    </div>
                    <p className="w-full text-lg flex justify-between items-center text-white font-semibold p-5">How to use ReceiptWallet?<FaPlus className="h-5 w-5 text-white cursor-pointer "/></p>
                    <div className="h-1 w-full bg-white">

                    </div>
                    <p className="w-full text-lg flex justify-between items-center text-white font-semibold p-5">Can i save my business records?<FaPlus className="h-5 w-5 text-white cursor-pointer "/></p>
                    <div className="h-1 w-full bg-white">

                    </div>
                    <p className="w-full text-lg flex justify-between items-center text-white font-semibold p-5">How secured is this software?<FaPlus className="h-5 w-5 text-white cursor-pointer "/></p>
                    <div className="h-1 w-full bg-lightestgray">

                    </div>
                    <p className="w-full text-lg flex justify-between items-center text-white font-semibold p-5">How long are my receipts stored?<FaPlus className="h-5 w-5 text-white cursor-pointer "/></p>
                    <div className="h-1 w-full bg-white">

                    </div>
                </div>
            </div>

        </div>
    )
}